import { useClickAway } from "react-use";
import Link from "next/link";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction } from "react";

interface IHeader {
  close: () => void;
  darkMode: boolean;
  onSetFormOpen: () => void;
}

export default function Header({ close, darkMode, onSetFormOpen }: IHeader) {
  const ref = useRef(null);

  useClickAway(ref, close);

  const pages = [
    { name: "Creators", path: "/socol-landing" },
    { name: "Tech", path: "/tech" },
    { name: "White Label", path: "/whitelabel" },
    { name: "About Us", path: "/about-us" },
    { name: "Blog", path: "/blog" },
    { name: "Contact Us", path: "/contact-us" },
  ];

  const router = useRouter();

  return (
    <div
      ref={ref}
      className={`${
        darkMode ? `bg-grey-900` : `bg-[#FDF6F3]`
      } absolute h-[100px] md:h-[214px] inset-x-0 flex items-center  rounded-b-[16px] z-20`}
    >
      <div className="mx-auto flex justify-between items-center w-[80%]">
        <button
          className="bg-red-500 text-white text-[14px] font-title italic px-[9px] py-[8px] rounded-[6px] uppercase"
          onClick={onSetFormOpen}
        >
          Sign up
        </button>
        {/* {pages.map((page) => (
          <Link href={page.path}>
            <a onClick={close}>
              <span className="text-[16px] uppercase">{page.name}</span>
              {router.asPath === page.path && (
                <span
                  style={{ transform: "matrix(0.97, 0, 0.27, -1, 0, 0)" }}
                  className="block w-[100%] bg-red-500 h-[6px]"
                />
              )}
            </a>
          </Link>
        ))} */}
        <button
          className="bg-red-500 h-[7px] w-[48px]"
          onClick={close}
          style={{ transform: "matrix(0.97, 0, 0.27, -1, 0, 0)" }}
        />
      </div>
    </div>
  );
}
