import React from "react";
import { useClickAway } from "react-use";
import PlusSVG from "@/lib/ui/img/landing/add.svg";

interface ICollapse {
  title: string;
  description: string;
}

export default function Collapse({ title, description }: ICollapse) {
  const [openDetails, setOpenDetails] = React.useState(false);
  const ref = React.useRef(null);

  useClickAway(ref, () => setOpenDetails(false));

  return (
    <div
      ref={ref}
      className={`${openDetails ? "row-1/4" : ""} max-w-[494px] w-full`}
    >
      <div
        className="flex justify-between pb-[45px] cursor-pointer"
        onClick={() => setOpenDetails(!openDetails)}
      >
        <h2 className="font-title italic text-[20px] text-semibold text-left select-none">
          {title}
        </h2>
        <button>
          <PlusSVG />
        </button>
      </div>
      {openDetails && (
        <p className="text-left pb-[45px] text-[16px]">{description}</p>
      )}
    </div>
  );
}
