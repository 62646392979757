import Link from "next/link";
import SocolLogoSVG from "@/lib/ui/img/landing/SocolLogo.svg";
import DiscordSVG from "@/lib/ui/img/landing/Discord.svg";
import TelegramSVG from "@/lib/ui/img/landing/Telegram.svg";
import InstagramSVG from "@/lib/ui/img/landing/Instagram.svg";
import TwitterSVG from "@/lib/ui/img/landing/Twitter.svg";

export default function Footer({
  onSetFormOpen,
}: {
  onSetFormOpen: () => void;
}) {
  const pages = [
    { name: "Creators", path: "/socol-landing" },
    { name: "Tech", path: "/tech" },
    { name: "White Label", path: "/whitelabel" },
    { name: "About Us", path: "/about-us" },
    { name: "Blog", path: "/blog" },
    { name: "Contact Us", path: "/contact-us" },
  ];

  return (
    <div className="px-[20px]">
      <div className="mx-auto pb-[64px] xl:pb-[120px] xl:max-w-[1218px] flex justify-between flex-col md:flex-row max-w-fit md:max-w-[900px]">
        <div className="max-w-[322px]">
          <h3 className="font-title pb-[16px] font-semibold italic uppercase text-[24px] leading-[17px] text-red-500">
            SO-COL
          </h3>
          <p className="text-[16px] mb-[32px]">
            A community-centric platform for fans and creators. Share a vision.
            Support a cause. Create change. Work with your creators, every step
            of the way.
          </p>
          <button
            onClick={onSetFormOpen}
            className="bg-red-500 text-white text-[14px] font-title italic px-[7px] py-[8px] rounded-[3px] uppercase border-[2px] border-white hover:text-red-500 hover:bg-white active:border-red-500"
          >
            Sign up now
          </button>
        </div>
        <ul className="max-w-[90px] my-[50px] md:my-[-11px] w-full">
          {/* {pages.map((page) => (
            <li key={page.path} className="py-[11px]">
              <Link href={page.path}>
                <a className="text-[16px] text-left">{page.name}</a>
              </Link>
            </li>
          ))} */}
        </ul>
        <div className="text-left md:text-center">
          <ul className="flex md:justify-center mb-[20px]">
            <li className="pr-[38px]">
              <a>
                <DiscordSVG />
              </a>
            </li>
            <li className="pr-[38px]">
              <a>
                <TwitterSVG />
              </a>
            </li>
            <li className="pr-[38px]">
              <a>
                <TelegramSVG />
              </a>
            </li>
            <li>
              <a>
                <InstagramSVG />
              </a>
            </li>
          </ul>
          <p className="text-[12px] pb-[47px]">
            Copyright © 2022 SO-COL. All Rights Reserved.
          </p>
          <SocolLogoSVG className="md:mx-auto" />
        </div>
      </div>
    </div>
  );
}
